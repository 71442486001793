<template>
  <div>
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <a class="menubar__button" :class="{ 'is-active': isActive.bold() }" click="commands.bold">
          <img class="icon" src="@/assets/icons/bold.svg" />
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <img class="icon" src="@/assets/icons/italic.svg" />
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <img class="icon" src="@/assets/icons/strike.svg" />
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <img class="icon" src="@/assets/icons/underline.svg" />
        </a>

        <a class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code">
          <img class="icon" src="@/assets/icons/code.svg" />
        </a>
        <!-- 
        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <img src="@/assets/icons/paragraph.svg" class="icon" />
        </a> -->

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <img src="@/assets/icons/ul.svg" class="icon" />
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <img src="@/assets/icons/ol.svg" class="icon" />
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <img src="@/assets/icons/quote.svg" class="icon" />
        </a>

        <a
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <img src="@/assets/icons/code.svg" class="icon" />
        </a>
        <!-- 
        <a class="menubar__button" @click="commands.horizontal_rule">
          <img src="@/assets/icons/hr.svg" class="icon" />
        </a> -->

        <a class="menubar__button" @click="commands.undo">
          <img src="@/assets/icons/undo.svg" class="icon" />
        </a>

        <a class="menubar__button" @click="commands.redo">
          <img src="@/assets/icons/redo.svg" class="icon" />
        </a>
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" v-model="content" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'

export default {
  name: 'editor',

  components: { EditorContent, EditorMenuBar },
  props: {
    contents: {
      type: String
    }
  },
  watch: {
    contents(value) {
      // Notice.vue 에서 수정한 데이터를 반영하기 위해
      this.editor.setContent(value)
    }
  },
  data() {
    return {
      content: '',
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new HardBreak(),
          new Heading(),
          new OrderedList(),
          new BulletList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: ``
      })
    }
  },
  methods: {
    getContent() {
      this.$emit('getContent', 'data')
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>

<style>
@import '../../assets/styles/editor.css';
</style>
